import React from "react";
import Layout from "../components/layout/layout";
import CTA from "../components/cta/cta";
import Banner from "../components/banner/banner";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import "../styles/services.scss";
import pageImage from "../images/Audio Mixing.png";

const MixingPage = () => {

  const data = useStaticQuery(graphql`
    query {
      file (relativePath: {eq: "paul-pilarte-o9Fg-XaW0iU-unsplash.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundImage = data.file.childImageSharp.fluid.src;

  return (
    <Layout
      title="Professional Online Mixing Services"
      description="Whether you've recorded in a home studio or professional studio, our Professional Online Mixing services will bring clarity, energy, and life to your music."
      image={pageImage}
    >
      <Banner
        title="Mixing"
        background={backgroundImage}
        height="300px"
      />
      <section className="our-process">
        <div className="container">
          <h3>What is Mixing?</h3>

          <p>
            Mixing is the process of taking a multi track recording and blending it all together to create a single stereo track. During this process, the mixing engineer will bring out the best in your recording by adjusting levels, panning, and effects. The aim is to sculpt your arrangement to make sense of all your tracks in relation to each other.
          </p>
          <h3>How to send your song</h3>

          <p>
            Export the audio stems of your song and upload them to a file transfer service such as Dropbox or Google Drive as a single .zip file.  Please also include an mp3 or WAV of the rough mix and any links to commercial songs that you feel are similar to the sound you’re going for. If you need help exporting the stems of your song, please let me know so that I can help you.
          </p>
          <h3>Turnaround time?</h3>

          <p>
            Turnaround time varies depending on the volume of work I receive, but on average 3-5 business days.
          </p>
          <h3>My vocals need some tuning. Can you help?</h3>
          <p>
            Of course! However, vocal tuning is not part of the mixing package since it is an editing service. There will be a separate charge for vocal tuning work.
          </p>
          <h3>Feedback/Revisions</h3>

          <p>
            Once the mix is complete, up to 2 mix revisions are allowed after the delivery of the first pass. Extra revisions are available upon request for a small fee.
          </p>
          <h3>Where can I hear samples of your work?</h3>
          <p>
            You can hear some samples of my most recent projects <Link to="/samples">here</Link>.
          </p>
        </div>
      </section>
      <section className="CTA">
        <div className="container">
          <h2>Ready To Get Started?</h2>
          <CTA text="Request Services" link="/contact"/>
        </div>
      </section>
    </Layout>
  );
};

export default MixingPage;
